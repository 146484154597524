<mat-toolbar class="container-header">
    <mat-toolbar-row class="nav_logo">
        <img class="logo" src="/assets/images/logo_navbar.svg" alt="logo de InventigAr" 
            routerLink="/select-test" routerLinkActive="active">
    </mat-toolbar-row>
</mat-toolbar>
<!-- <hr class="line" id="line-nav"> -->
<!-- <mat-toolbar-row class="navbar">
    <nav class="nav">
        <a routerLink="/instructions-creativity" routerLinkActive="active"
            mat-button>CREATIVIDAD</a>
        <a mat-button>INHIBICIÓN</a>
        <a mat-button>MEMORIA</a>
        <a mat-button>PLANEAMIENTO</a>
    </nav>
</mat-toolbar-row> -->


