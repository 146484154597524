import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-question',
  templateUrl: './header-question.component.html',
  styleUrls: ['./header-question.component.scss']
})
export class HeaderQuestionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
