import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-dark-isologo',
  templateUrl: './header-dark-isologo.component.html',
  styleUrls: ['./header-dark-isologo.component.scss']
})
export class HeaderDarkIsologoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
