<mat-toolbar class="container-header">
    <mat-toolbar-row class="nav_logo">
        <img class="logo" src="./assets/images/logo_navbar.svg" alt="logo de InventigAr"
            routerLink="/select-test" routerLinkActive="active">
            <div class="row container-question-link"
                routerLink="/instructions-creativity/312917" routerLinkActive="active">
                <div class="col container-col">
                    <span class="material-icons question">
                        contact_support
                    </span>
                    <!-- <img class="question" src="\assets\images\question.png" alt="acceso a preguntas o ayuda"> -->
                </div>
                <div class="col container-col">
                    <h4 class="type-activity">Usos alternativos</h4>
                </div>
            </div>
    </mat-toolbar-row>
</mat-toolbar>
