import { Component } from '@angular/core';

@Component({
  selector: 'app-header-encode',
  templateUrl: './header-encode.component.html',
  styleUrls: ['./header-encode.component.scss']
})
export class HeaderEncodeComponent {

  constructor() { }

}
