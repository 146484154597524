<article class="container-footer">
    <figure class="container-logos">
        <div class="container_itba_conicet">
            <img (click)="goITBA()" class="logo-itba" src="assets\images\logo_ITBA.svg" alt="Logo de Instituto Tecnológico de Buenos Aires">
            <img (click)="goIBCN()" class="logo-conicet" src="assets\images\logo_CONICET.svg" alt="Logo de CONICET">
        </div>
        <div class="container_unicen_pladema">
            <img (click)="goUNICEN()" class="logo-unicen" src="assets\images\logo_UNICEN.svg" alt="Logo de Universidad Nacional del Centro de la Provincia de Buenos Aires">             
            <!-- <img (click)="goUNICEN()" class="logo-unicen-hover" src="assets\images\logo_UNICEN_hover.svg" alt="Logo de Universidad Nacional del Centro de la Provincia de Buenos Aires">    -->
            <img (click)="goMediaLab()" class="logo-pladema" src="assets\images\logo_Pladema_MediaLab.svg" alt="Logo del Laboraorio de Investigación Pladema | MediaLab">
        </div>
        <div class="container_tuxdi">
            <img (click)="goTuxdi()" class="logo-tuxdi" src="assets\images\logo_TUXDI.svg" alt="Logo de Tuxdi">
        </div>
    </figure>
    <figure class="container-logos-mobile">
        <div class="container_itba_conicet">
            <img (click)="goITBA()" class="logo-itba" src="assets\images\logo_ITBA.svg" alt="Logo de Instituto Tecnológico de Buenos Aires">
            <img (click)="goIBCN()" class="logo-conicet" src="assets\images\logo_CONICET.svg" alt="Logo de CONICET">
        </div>
        <div class="container_unicen_pladema">
            <img (click)="goUNICEN()" class="logo-unicen" src="assets\images\logo_UNICEN.svg" alt="Logo de Universidad Nacional del Centro de la Provincia de Buenos Aires">
            <img  (click)="goMediaLab()" class="logo-pladema" src="assets\images\logo_Pladema_MediaLab.svg" alt="Logo del Laboraorio de Investigación Pladema | MediaLab">
        </div>
        <div class="container_tuxdi">
            <img (click)="goTuxdi()" class="logo-tuxdi" src="assets\images\logo_TUXDI.svg" alt="Logo de Tuxdi">
        </div>
    </figure>
</article>