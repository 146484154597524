<mat-toolbar class="container-header">
    <mat-toolbar-row class="nav_logo">
        <img class="logo" src="/assets/images/logo_navbar.svg" alt="logo de InventigAr"
            routerLink="/home" routerLinkActive="active">
            <div class="row container-icon-creativity"
                routerLink="/select-test" routerLinkActive="active">
                <div class="col container-col">
                    <img class="question" src="assets\images\crativity-header.png" alt="icono de la actividad Creatividad">
                </div>
                <div class="col container-col">
                    <h4 class="type-activity">CREATIVIDAD</h4>
                </div>
            </div>
    </mat-toolbar-row>
</mat-toolbar>
