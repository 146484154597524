<div class="container-all">
    <div class="container-message">
        <h4 class="mesagge">Estás a punto de abandonar la actividad.</h4>
        <p>¿Querés abandonar?</p>
    </div>
    <div class="container-button">
        <button (click)="goTest()" class="button" mat-raised-button
            routerLink="/test-creativity" routerLinkActive="active"
            data-backdrop="static" data-keyboard="false">
            ABANDONAR
        </button>
        <button (click)="goTest()" class="button" mat-raised-button
            routerLink="/test-creativity" routerLinkActive="active"
            data-backdrop="static" data-keyboard="false">
            SEGUIR
        </button>    
    </div>
</div>
